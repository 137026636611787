@charset "UTF-8";

/* *************************************************************** */
// 独自仕様
/* *************************************************************** */

// 関数
//--------------------------------------------------
@function tint-color($color, $weight) {
  @return mix(white, $color, $weight);
}

@function shade-color($color, $weight) {
  @return mix(black, $color, $weight);
}

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }
  @return $string;
}

// mixin
//--------------------------------------------------
@mixin text-gold() {
  -webkit-background-clip: text;
  // background-image: repeating-linear-gradient(
  //   30deg,
  //   #f0b133 2em,
  //   #daaf08 4em,
  //   #fee9a0 6em,
  //   #daaf08 8em,
  //   #f0b133 10em
  // );
  background-image: repeating-linear-gradient(
    315deg,
    #b67b03 2em,
    #daaf08 4em,
    #fee9a0 6em,
    #daaf08 8em,
    #b67b03 10em
  );
  color: transparent;
}
@mixin text-silver() {
  -webkit-background-clip: text;
  background-image: repeating-linear-gradient(
    30deg,
    #757575 2em,
    #9e9e9e 4em,
    #ccc 6em,
    #9e9e9e 8em,
    #757575 10em
  );
  color: transparent;
}
@mixin bg-stripe() {
  background: repeating-linear-gradient(
    45deg,
    #fff,
    #fff 10px,
    #eee 10px,
    #eee 20px
  );
}
@mixin bg-ichimatsu() {
  $ichimatsu-bgc: #fff;
  $ichimatsu-accent: #eee;
  $ichimatsu-width: 20px;

  background: linear-gradient(
        45deg,
        $ichimatsu-accent 25%,
        transparent 25%,
        transparent 75%,
        $ichimatsu-accent 75%,
        $ichimatsu-accent 100%
      )
      0 0,
    linear-gradient(
        45deg,
        $ichimatsu-accent 25%,
        $ichimatsu-bgc 25%,
        $ichimatsu-bgc 75%,
        $ichimatsu-accent 75%,
        $ichimatsu-accent 100%
      )
      $ichimatsu-width $ichimatsu-width;
  background-size: ($ichimatsu-width * 2) ($ichimatsu-width * 2);
}

// フォント指定
//--------------------------------------------------
// ja sans serif
@import url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/css/yakuhanjp.min.css");
@import url("https://fonts.googleapis.com/css2?family=Kiwi+Maru:wght@300;400;500&display=swap");

// ja serif
@import url("https://cdn.jsdelivr.net/npm/yakuhanjp@3.4.1/dist/css/yakuhanmp.min.css");

// en sans serif
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");

// en serif
// @import url("https://fonts.googleapis.com/css2?family=Marcellus&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700;800;900&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

// en 筆記体
@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Reggae+One&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap");

// アイコンセット
@import url("https://use.fontawesome.com/releases/v5.14.0/css/all.css");

// サンセリフ
/* prettier-ignore */
$font_ja:
  YakuHanJP,
  // "Kiwi Maru",
  游ゴシック体,
  YuGothic,
  "游ゴシック Medium",
  "Yu Gothic Medium",
  游ゴシック,
  "Yu Gothic",
  "Helvetica Neue",
  Helvetica,
  "Hiragino Sans",
  "Hiragino Kaku Gothic ProN",
  Arial,
  Meiryo,
  sans-serif;
$font_en: "Josefin Sans", sans-serif;

// セリフ
/* prettier-ignore */
$font_ja_serif:
 YakuHanMP,
 "Times New Roman",
 YuMincho,
 "Hiragino Mincho ProN",
 "Yu Mincho",
 "MS PMincho",
 serif;
$font_en_serif: "Cinzel", serif;

// サイト内のベースフォントを指定
$font_family_base: $font_ja;
$font_family_en_base: $font_en;
$font_family_heading: $font_ja;
$font_family_handwriting: "Pinyon Script", cursive;

// 特定の英単語の大文字小文字を制御
$letter_case: none;

// フォント切替
//--------------------------------------------------
.en {
  font-family: $font_en;
}
.en-serif {
  font-family: $font_en_serif;
  line-height: 1.2;
}
.en-hw {
  font-family: $font_family_handwriting;
  letter-spacing: 0;
}
.ja {
  font-family: $font_ja;
}
.ja-serif {
  font-family: $font_ja_serif;
}

// サイトカラー
//--------------------------------------------------
$primary: #009e41;
$secondary: #84cc94;
$tertiary: #6fc450;
$success: #198754;
$info: #0dcaf0;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #212529;
$gray: #eee;
$beige: #faf5ea;
// $beige: #fffae2;

// テキストカラー
.text-primary {
  color: $primary !important;
}
.text-secondary {
  color: $secondary !important;
}
.text-tertiary {
  color: $tertiary !important;
}
.text-success {
  color: $success !important;
}
.text-info {
  color: $info !important;
}
.text-warning {
  color: $warning !important;
}
.text-danger {
  color: $danger !important;
}
.text-light {
  color: $light !important;
}
.text-dark {
  color: $dark !important;
}
.text-gray {
  color: $gray !important;
}
.text-beige {
  color: $beige !important;
}
.text-fa {
  color: #fafafa !important;
}
.text-gold {
  @include text-gold();
}
.text-silver {
  @include text-silver();
}

// 背景色
.bg-primary {
  background-color: $primary !important;
}
.bg-secondary {
  background-color: $secondary !important;
}
.bg-tertiary {
  background-color: $tertiary !important;
}
.bg-success {
  background-color: $success !important;
}
.bg-info {
  background-color: $info !important;
}
.bg-warning {
  background-color: $warning !important;
}
.bg-danger {
  background-color: $danger !important;
}
.bg-light {
  background-color: $light !important;
}
.bg-dark {
  background-color: $dark !important;
}
.bg-gray {
  background-color: $gray !important;
}
.bg-beige {
  background-color: $beige !important;
}
.bg-fa {
  background-color: #fafafa !important;
}

// ベジェ曲線
//--------------------------------------------------
$linear: cubic-bezier(0, 0, 1, 1);

$easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715);
$easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easeInOutSine: cubic-bezier(0.445, 0.05, 0.55, 0.95);

$easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);

$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);

$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);

$easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeInOutQuint: cubic-bezier(0.86, 0, 0.07, 1);

$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo: cubic-bezier(1, 0, 0, 1);

$easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.15, 0.86);

$easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);

// フォームの色指定
//--------------------------------------------------
.form-control {
  &:focus {
    border-color: $primary !important;
    box-shadow: 0 0 0 0.25rem rgba($primary, 0.3) !important;
  }
  &[readonly] {
    background-color: #fff !important;
  }
}
.form-check-input {
  &:checked {
    background-color: $primary !important;
    border-color: $primary !important;
  }
  &:focus {
    border-color: $primary !important;
    box-shadow: 0 0 0 0.25rem rgba($primary, 0.3) !important;
  }
}

.accordion-button {
  &:not(.collapsed) {
    background-color: rgba($primary, 0.1) !important;
    box-shadow: inset 0 -1px 0 rgba($primary, 0.5) !important;
    $color: str-replace("#{$primary}", "#", "");
    &::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23#{$color}'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
    }
  }
  &:focus {
    border-color: rgba($primary, 0.1) !important;
    box-shadow: 0 0 0 0.25rem rgba($primary, 0.5) !important;
  }
}
